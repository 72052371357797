import { hasNames } from "../utils";
import * as React from "react";
import { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as com from "../styles/common.module.scss";
import * as styles from "../styles/feature-tile.module.scss";

const FeatureTile = ({
	image,
	title,
	body,
  className,
  index
}) => {
  const [defaultState, setDefaultState] = useState(true);
  const [hovered, setHovered] = useState(false);

  const toggleHover = () => {
    setHovered(!hovered);
    setDefaultState(!defaultState);

    const firstFeature = document.getElementsByClassName(styles.featureTile)[0];
    if ( firstFeature && firstFeature.classList.contains('defaultState') ) {
      firstFeature.classList.remove('defaultState');
      firstFeature.firstElementChild.classList.remove(com.imageClippedTopLeft);
    } else {
      firstFeature.classList.add('defaultState');
      firstFeature.firstElementChild.classList.add(com.imageClippedTopLeft);
    }
  }

	return (

		<div className={hasNames(styles.featureTile, className, index == 0 ? 'defaultState' : '')}
      onMouseEnter={index === 1 || index === 2 ? toggleHover : null}
      onMouseLeave={index === 1 || index === 2 ? toggleHover : null}
    >
      <GatsbyImage
        image={getImage(image)}
        alt={title}
        className={hasNames(com.imageClippedTopLeftMobile, styles.imageWrapper,
          hovered && (index === 1 || index === 2) ? com.imageClippedTopLeft : '',
          index === 0 && defaultState ? com.imageClippedTopLeft : '')}
        height={414}
      />
      <div className={styles.textWrapper}>
        <h2 className={styles.title}>{title}</h2>
        <p>{body}</p>
      </div>
		</div>
	);
};

export default FeatureTile;
