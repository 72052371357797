import { hasNames } from "../utils";
import { graphql } from "gatsby";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { useEffect, useRef } from "react";
import * as React from "react";

import Button from "../components/button";
import ContactUsFooter from "../components/contact-us-footer";
import FiftyFifty from "../components/fifty-fifty";
import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";
import StaticHero from "../components/static-hero";
import InteractiveScroll from "../components/interactive-scroll";
import FeatureTile from "../components/feature-tile";

import * as com from "../styles/common.module.scss";
import * as grd from "../styles/grid.module.scss";
import * as styles from "../styles/index.module.scss";
import * as typ from "../styles/typography.module.scss";

import FeaturesOverlay from "../images/logo-shape-overlay.inline.svg";

export const Head = () => {
	return <HeadMeta title="Cynosure Strategies Hedge Fund" />;
};

const IndexPage = ({ data }) => {
	const investmentRef = useRef(null);
	const teamRef = useRef(null);
	const contactRef = useRef(null);

	const scrollLocations = [
		{ label: "Investment Strategy", ref: investmentRef },
		{ label: "Our Team", ref: teamRef },
		{ label: "Footer", ref: contactRef },
	];

	return (
		<Frame>
			<InteractiveScroll locations={scrollLocations} />
			<StaticHero image="Meeting">
				<h1>A data-driven approach to hedge fund investing</h1>
				Seeded by the Eccles family, our quantitative framework incorporates the lessons of behavioral finance. We aim to deliver the highest and most consistent total returns possible—regardless of economic and market conditions—while minimizing the risk of loss.  
			</StaticHero>
			<div className={styles.backCover} ref={investmentRef}>
				<div className={hasNames(styles.childWrap, grd.gridContainer)}>
					<div className={hasNames(grd.col8, grd.colSm12, com.sectionSpacer)}>
						<div className={typ.eyebrow}>Investment Strategy</div>
						<h2 className={com.browedHeader}>
						A portfolio of uncorrelated, systematic return drivers
						</h2>
						<div className={typ.bodyLargeLight}>
						<p>Investors are human. And as markets shift, we tend to make emotional and impulsive decisions that sometimes lead to suboptimal investment performance. To counter this, we apply several uncorrelated quantitative models to portfolio management decisions, systematically deploying capital across long and short positions.</p>
						<p>Our market-neutral strategy seeks to generate positive returns in every market environment, minimize downside risk, and add diversification in multi-asset portfolios by maintaining the following attributes:</p>
						</div>
					</div>
				</div>
      </div>
      <div className={hasNames(styles.blueBackground, com.animDesktop)} ref={teamRef}>
        <FeaturesOverlay className={styles.featuresOverlay} />
        <div className={hasNames(grd.container, grd.featuresContainer)}>
          {data.features.nodes.map((features, index) => {
            return (
              <FeatureTile
                key={"home-page-accordion-" + features.id}
                className={hasNames()}
                image={features.frontmatter.image}
                title={features.frontmatter.title}
                body={features.frontmatter.body}
                index={index}
              />
              );
          })}
        </div>
      </div>
      <div className={styles.backCover}>
				<div className={hasNames(com.animMobile)}>
					<FiftyFifty className={styles.lottieAnimation}>
						<StaticImage
								src="../images/low-correlation-to-markets.jpg"
								alt="First Security Building"
								aspectRatio={1 / 1}
								layout="constrained"
								width={640}
								height={640}
								className={com.imageClippedTopLeftMobile}
								quality={85}
							/>
						<div className={hasNames(styles.leftHalf, styles.featuresLeftHalf)}>
							<h2 className={com.browedHeader}>
							Low correlation to stock and bond markets
							</h2>
							<div className={typ.bodyLargeLight}>
								<p>Most investors have significant exposure to equity, credit and duration risk factors. Our design is to be a pure source of alpha that's uncorrelated with major beta drivers in an effort to help our investors achieve better overall returns with lower volatility.</p>
							</div>
						</div>
					</FiftyFifty>
					<FiftyFifty className={styles.lottieAnimation}>
						<StaticImage
								src="../images/equity-like-returns.jpg"
								alt="First Security Building"
								aspectRatio={1 / 1}
								layout="constrained"
								width={640}
								height={640}
								className={com.imageClippedTopLeftMobile}
								quality={85}
							/>
						<div className={hasNames(styles.leftHalf, styles.featuresLeftHalf)}>
							<h2 className={com.browedHeader}>
							Equity-like returns
							</h2>
							<div className={typ.bodyLargeLight}>
								<p>Our approach is intended to complement our investors' portfolio allocations. Our strategy is calibrated to move enough that it delivers meaningful diversification benefits but not so much that it introduces unwanted volatility.</p>
							</div>
						</div>
					</FiftyFifty>
					<FiftyFifty className={styles.lottieAnimation}>
						<StaticImage
								src="../images/minimal-drawdowns.jpg"
								alt="First Security Building"
								aspectRatio={1 / 1}
								layout="constrained"
								width={640}
								height={640}
								className={com.imageClippedTopLeftMobile}
								quality={85}
							/>
						<div className={styles.leftHalf}>
							<h2 className={com.browedHeader}>
							Minimal drawdowns
							</h2>
							<div className={typ.bodyLargeLight}>
								<p>We maintain a large number of uncorrelated and positively-convex positions with upside potential that exceeds their perceived risk of loss. This feature aims to keep the depth and duration of drawdowns to a minimum even as the strategy targets outsized returns.</p>
							</div>
						</div>
					</FiftyFifty>
        </div>
			</div>
			<FiftyFifty useRef={teamRef}>
					<StaticImage
							src="../images/strategies-our-team.jpg"
							alt="First Security Building"
							aspectRatio={1 / 1}
							layout="constrained"
							width={640}
							height={640}
							className={com.imageClippedLeft}
							quality={85}
						/>
					<div className={styles.leftHalf}>
						<div className="extra-gap">
							<div className={typ.eyebrow}>Our Team</div>
								<h2 className={com.browedHeader}>
								Recognized Expertise
								</h2>
								<div className={typ.bodyLargeLight}>
								<p>Brian Smedley is a Managing Director and Head of Cynosure Strategies. He manages the firm's hedge fund investment portfolio as the Chief Investment Officer. He previously held senior positions at Guggenheim Investments, Bank of America and the Federal Reserve Bank of New York.</p>
								<p>Randal Quarles is the Chairman and Co-Founder of The Cynosure Group. Prior to forming Cynosure in 2014, he spent many years as a partner at the Carlyle Group and held senior financial policy positions in four different presidential administrations. He served most recently as Vice Chair for Supervision of the Federal Reserve Board of Governors. </p>
								</div>
						</div>
						<Button to="/team/" type="secondary" className={com.leftAlignedButton}>
							Meet the Team
						</Button>
					</div>
				</FiftyFifty>
				<ContactUsFooter ref={contactRef} />
		</Frame>
	);
};

export default IndexPage;

export const query = graphql`
	query HomePageQuery {
		newsArticles: allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/src/cms/data/news/" } }
			sort: { fields: frontmatter___date, order: DESC }
			limit: 3
		) {
			nodes {
				id
				frontmatter {
					title
					date(formatString: "MMMM Do YYYY")
					tags
				}
				fields {
					url
				}
				excerpt(pruneLength: 128)
			}
		}
    features: allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/src/cms/data/features/" } }
      sort: { fields: frontmatter___position }
			limit: 3
    ) {
      nodes {
        id
        frontmatter {
          image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
              )
            }
          }
          title
          body
        }
      }
    }
	}
`;
